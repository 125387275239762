import './App.css';
import MasyWebSocket from "./connection/MasyWebSocket";
import MasyDropdownMenu from "./components/MasyDropdownMenu";
import {useEffect, useMemo, useRef, useState} from "react";
import MasyToolbar from "./components/MasyToolbar";
import MasyMenu from "./components/MasyMenu";
import MasyFields from "./components/MasyFields";
import MasyStatus from "./components/MasyStatus";
import MasyZusatz from "./components/MasyZusatz";
import {Modal} from "./components/Modal";
import NewEntry from "./components/NewEntry";
import MasyBurgerMenu from "./components/MasyBurgerMenu";
import {MasyHover} from "./components/MasyHover";
import LoadingSpinner from "react-loading";

function App(){
    const [masyGui, setMasyGui] = useState({});
    const [masyGuiFields, setMaysGuiFields] = useState([]);
    const sendToMasyRef = useRef(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isMasy2, setIsMasy2] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [stats, setStats] = useState(null);
    const [search, setSearch] = useState(null);
    const [table, setTable] = useState(null);
    const [modal, setModal] = useState(null);
    const [editing, setEditing] = useState(false);
    const [newEntry, setNewEntry] = useState(null);
    const [isMobil, setIsMobil] = useState(window.innerWidth < 1200);
    const [activeField, setActiveField] = useState(null);
    const [tooltip, setTooltip] = useState(null);
    const [masyPassword, setMasyPassword] = useState(localStorage.getItem("creds") ? "masyDemo1" : "");
    const [isLoading, setIsLoading] = useState(true);
    const reFocusRef = useRef(null);

    useMemo(() => {
        console.log("start socket");
        MasyWebSocket(
            setMasyGui,
            sendToMasyRef,
            setMaysGuiFields,
            setLoggedIn,
            setIsMasy2,
            setIsConnected,
            setStats,
            setSearch,
            setModal,
            setEditing,
            setNewEntry,
            setTable,
            isMobil,
            setIsLoading);
    }, []);

    useEffect(() => {
        if(isConnected && !loggedIn){
            document.getElementById("usernameInput")?.focus();
        }
    }, [isConnected, loggedIn]);

    function onKey(e){
        console.log("app listender " + e.key);
        if(e.key === "F5" && !isMasy2){
            return;
        }
        if(e.key === "Escape"){
            window.close();
        }
        if(e.key === "Enter" && !isConnected){
            window.location.reload();
        }
        else if(e.key === "Enter"){
            if(document.getElementById("input")){
                document.getElementById("input").focus();
            }
            else{
                document.getElementById("passwordInput")?.focus();
            }
        }
        if(e.key.match("F[0-9]+")){
            e.preventDefault();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKey, false);

        return () => {
            window.removeEventListener("keydown", onKey, false);
        };
    }, [search, editing, isMasy2, isConnected]);

    useEffect(() => {
        console.log(masyGui);
    }, [masyGui]);

    return (
        <div className="App" style={{width: window.innerWidth, height: window.innerHeight}} id={"App"}>
            {modal && <Modal text={modal.text} text1={modal.ok ?? "OK"} text2={modal.abort}
                             onClick1={modal.okFun ?? (() => setModal(null))}
                             onClick2={modal.abortFun ?? (() => setModal(null))}
                             isMobil={isMobil} reFocusRef={reFocusRef} header={modal.header}
            />}
            {isLoading &&
                <div className="loadingSpinnerBackground centerVertical"><LoadingSpinner className="loadingSpinner"
                                                                                         type={"spin"}
                                                                                         width={isMobil ? "20%" : "2%"}
                                                                                         color={"#8BB8B3"}/></div>}
            {isConnected ? !isMasy2 && (!masyPassword || masyPassword !== "masyDemo1") ?
                    <div>
                        MasyPasswort eingeben: <input id={"passwordInput"} type={"password"}
                                                      onChange={(e) => setMasyPassword(e.target.value)}
                                                      value={masyPassword}/>
                    </div> :
                    !loggedIn ?
                        <div>
                            Benutzername: <input id={"usernameInput"}
                                                 onKeyDown={(e) => e.key === "Enter" && document.getElementById("passwordInput").focus()}
                                                 onChange={(e) => setUserName(e.target.value.toUpperCase())}
                                                 value={username}/>
                            Passwort: <input id={"passwordInput"} type={"password"}
                                             onKeyDown={(e) => {
                                                 if(e.key === "Enter"){
                                                     if(isMobil){
                                                         localStorage.setItem("creds", btoa(JSON.stringify({
                                                             username,
                                                             password
                                                         })));
                                                     }
                                                     sendToMasyRef.current("login: " + JSON.stringify({
                                                         username,
                                                         password
                                                     }), true);
                                                 }
                                             }}
                                             onChange={(e) => setPassword(e.target.value)} value={password}/>
                            <button className={"button"} onClick={() => {
                                if(isMobil){
                                    localStorage.setItem("creds", btoa(JSON.stringify({username, password})));
                                }
                                sendToMasyRef.current("login: " + JSON.stringify({
                                    username,
                                    password
                                }), true);
                            }}>login
                            </button>
                        </div> :
                        !isMasy2 ?
                            <div style={{maxWidth: window.screen.availWidth}}>
                                {
                                    !isMobil ?
                                        <MasyDropdownMenu masyGui={masyGui} onClick={sendToMasyRef.current}/> :
                                        <MasyBurgerMenu masyGui={masyGui} onClick={sendToMasyRef.current}
                                                        isMasy2={isMasy2}/>
                                }
                                {!isMobil && <MasyToolbar masyGui={masyGui}/>}
                                <MasyMenu masyGui={masyGui} onAction={sendToMasyRef.current} isMobil={isMobil} stats={stats}
                                          setStats={setStats}/>
                                {/*{!isMobil &&*/}
                                {/*    <button onClick={() => sendToMasyRef.current("stats", true)}>Zeige Offene Masys</button>}*/}
                                <div style={{display: "flex", flexDirection: "row", position: "absolute", bottom: 0}}>
                                    {stats && stats.anz_user ? Object.keys(stats).map((user) =>
                                        user === "anz_user" ? <div>{user + ": " + stats[user]}</div> :
                                            <div>
                                                <div>{user + ": "}</div>
                                                {stats[user].map((masy) => masy.isMasy === "true" ?
                                                    <button style={{
                                                        margin: 10,
                                                        background: masy.isConnectedMain === "true" ? "gold" : "gray"
                                                    }}
                                                            onClick={() => masy.isConnectedMain !== "true" ? sendToMasyRef.current("connect: " + masy.id) : null}>
                                                        {"id: " + masy.id}
                                                    </button> : null)}
                                            </div>
                                    ) : stats?.map((masy) => masy.isMasy === "true" ?
                                        <button
                                            style={{
                                                margin: 10,
                                                background: masy.isConnectedMain === "true" ? "gold" : "gray"
                                            }}
                                            onClick={() => masy.isConnectedMain !== "true" ? sendToMasyRef.current("connect: " + masy.id) : null}>
                                            {"id: " + masy.id}
                                        </button> : null)
                                    }
                                </div>
                                {/*<br/><input onChange={(e) => setInput(e.target.value)} value={input}/>*/}
                                {/*<button onClick={() => sendToMasyRef.current(input)}>send</button>*/}
                            </div> :
                            <div style={{maxWidth: window.screen.availWidth}}>
                                {!isMobil && tooltip && <MasyHover tooltip={tooltip}/>}
                                {isMobil && <button style={{
                                    width: "100%",
                                    textAlign: "center",
                                    height: 50,
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    zIndex: 10
                                }} onClick={() => {
                                    window.location.replace("/");
                                }}>{"<- zurück zum Hauptmenü"}</button>}
                                {isMobil && <div style={{height: 50}}></div>}
                                {
                                    !isMobil ?
                                        <MasyDropdownMenu masyGui={masyGui} onClick={sendToMasyRef.current}/> :
                                        <MasyBurgerMenu masyGui={masyGui} onClick={sendToMasyRef.current}
                                                        isMasy2={isMasy2}/>
                                }
                                {newEntry &&
                                    <NewEntry data={newEntry} setData={setNewEntry} onAction={sendToMasyRef.current}
                                              isMobil={isMobil}/>}
                                <MasyToolbar masyGui={masyGui} onAction={sendToMasyRef.current} editing={editing}
                                             isMobil={isMobil}/>
                                <MasyFields fields={masyGuiFields} search={search} setSearch={setSearch}
                                            onAction={sendToMasyRef.current} setModal={setModal}
                                            setFields={setMaysGuiFields}
                                            editing={editing} table={table} setTable={setTable} sortedBy={masyGui.sortedBy}
                                            isMobil={isMobil} onDblClick={(i) => {
                                    setActiveField(i);
                                    sendToMasyRef.current("action: " + masyGui?.toolbar?.find((item) => item.caption?.toString().includes("ndern")).action);
                                }} activeField={activeField} setActiveField={setActiveField} reFocusRef={reFocusRef}
                                            setTooltip={setTooltip}/>
                                {!editing &&
                                    <MasyStatus masyGui={masyGui} onAction={sendToMasyRef.current} isMobil={true}/>}
                                {!editing && <MasyZusatz masyGui={masyGui}/>}
                                {/*<br/><input onChange={(e) => setInput(e.target.value)} value={input}/>*/}
                                {/*<button onClick={() => sendToMasyRef.current(input)}>send</button>*/}
                            </div> :
                <div>
                    is not connected. <br/>
                    <button onClick={() => window.location.reload()}>refresh</button>
                </div>}
        </div>
    );
}

export default App;
