import {useEffect} from "react";

export function Modal({header, text, text1, text2, onClick1, onClick2, isMobil, reFocusRef}){

    const styles = {
        warning: {
            position: isMobil ? "fixed" : "absolute",
            height: "100vh",
            width: "100vw",
            zIndex: 10,
            background: "#080E0D",
            opacity: 0.95,
            padding: "10%",
            top: 0,
            left: 0
        }
    };
    useEffect(() => {
        document.getElementById("okButton")?.focus();
        window.addEventListener("keydown", onKey, true);

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    });

    function onKey(e){
        if(e.key === "Escape"){
            onClick2 ? onClick2() : onClick1();
            e.stopImmediatePropagation();
            e.preventDefault();
        }
        if(e.key === "Enter"){
            onClick1 ? onClick1() : onClick2();
            e.stopImmediatePropagation();
            e.preventDefault();
        }
        if(typeof reFocusRef.current === "function"){
            reFocusRef.current();
        }
    }

    return (<div style={styles.warning} className={"centerVertical"}>
        <div>
            {header && <h1 style={{color: header.includes("FEHLER")? "red": undefined}}>{header}</h1>}
            <div style={{color: "white", whiteSpace: "break-spaces"}}>{text}</div>
            <br/>
            <div className={"gridtwo"}>
                {text1 && <button id={"okButton"} onClick={() => {
                    onClick1();
                    if(typeof reFocusRef.current === "function"){
                        reFocusRef.current();
                    }
                }}>{text1}</button>}
                {text2 && <button onClick={() => {
                    onClick2();
                    if(typeof reFocusRef.current === "function"){
                        reFocusRef.current()
                    }
                }}>{text2}</button>}
            </div>
        </div>
    </div>);
}
